import { getItem } from 'service/storage.service';

class Configuration {
  static readonly isTestEnv: boolean = import.meta.env.VITE_IS_TEST_ENV === 'true' || false;

  static readonly frontendUrl: any = import.meta.env.VITE_FRONTEND_URL;

  static readonly backendUrl: any = getItem('backendUrl') || import.meta.env.VITE_BACKEND_URL;

  static readonly cognitoUrl: any = import.meta.env.VITE_COGNITO_URL;

  static readonly cognitoClientId: any = import.meta.env.VITE_COGNITO_CLIENT_ID;

  static readonly loginUrl: any =
    `${Configuration.cognitoUrl}/login?response_type=code&client_id=${Configuration.cognitoClientId}&redirect_uri=${Configuration.frontendUrl}/login&scope=openid+profile+email`;

  static readonly googleAnalyticsTrackingId: any = import.meta.env
    .VITE_GOOGLE_ANALYTICS_TRACKING_ID;

  static readonly hotjarSiteId: any = import.meta.env.VITE_HOTJAR_SITE_ID;

  static readonly handsontableLicense: any = import.meta.env.VITE_HANDSONTABLE_KEY;

  static readonly magicApiKey: string = String(import.meta.env.VITE_MAGIC_API_KEY) || '';

  static readonly jssLicence: any = import.meta.env.VITE_JSS_LICENCE;

  static readonly msLoginClientId: any = import.meta.env.VITE_MS_LOGIN_CLIENT_ID;

  static readonly googleLoginClientId: any = import.meta.env.VITE_GOOGLE_LOGIN_CLIENT_ID;
}

export default Configuration;
